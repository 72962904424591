import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from "react-bootstrap";

import  "./assets/_index.scss";

import AlertIcon from '@icons/alert.inline.svg';
import LeftChevron from '@icons/left-chevron.inline.svg';

const BackToolbar = (props) => {
    const handleBack = () => window.history.back();
    return (
        <section className={`section section-back-toolbar${props.sectionClass}`}>
            <Container>
                <Row>
                    <Col xs={12} className={props.toolbarClass}>
                        {props.showBackLink &&
                            <Button variant="none" className="btn-none-icon-adjustment font-weight-normal back-btn" onClick={handleBack} onKeyPress={handleBack} role="presentation"><LeftChevron />{props.backLinkLabel}</Button>
                        }
                        {props.showCreateAlert &&
                            <Button variant="none" className="btn-none-icon-adjustment alert-btn"><AlertIcon />Create <span className="d-none d-sm-inline">Job</span> Alert</Button>
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

// Check all the prop types
BackToolbar.propTypes = {
    sectionClass: PropTypes.string,
    toolbarClass: PropTypes.string,
    showBackLink: PropTypes.bool,
    backLinkLabel: PropTypes.string,
    showCreateAlert: PropTypes.bool
};

// Specifies the default values for props:
BackToolbar.defaultProps = {
    sectionClass: ' white-bg py-15 py-md-24',
    toolbarClass: 'd-flex justify-content-between',
    showBackLink: true,
    backLinkLabel: " Back to Job Details",
    showCreateAlert: true
};

export default BackToolbar