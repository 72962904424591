import React from 'react';
import { Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Label from './label';

const FileField = ({fieldClass, type, name, value, required, placeholder, handlechange, pattern, label, grpmd, btnLabel, dataBrowse, accept}) => (
  <Form.Group as={Col} md={grpmd} controlId={"validation" + name}>
    <Label label={label} required={required} />
    <Form.File
        className={fieldClass}
        required={required}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={handlechange}
        pattern={pattern}
        label={btnLabel}
        data-browse={dataBrowse}
        accept={accept}
        custom
    />
  </Form.Group>
);

export default FileField;